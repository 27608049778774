import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useScreenWidth from "../lib/useGetScreenWidth";
import useCompareValues from "../lib/useCompareValues";
import formatNumber from "../lib/formatNumber";
import { BiayaTambahan } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import TidakAdaData from "./TidakAdaData";
import ComponentSpinner from "./ComponentSpinner";
import AksiUpdateDeleteBiayaTambahan from "./AksiUpdateDeleteBiayaTambahan";
import useTrigger from "../globalState/useTrigger";
import useSearchBiayaTambahan from "../globalState/useSearchBiayaTambahan";
import useSortBiayaTambahan from "../globalState/useSortBiayaTambahan";

export default function TabelBiayaTambahan() {
  const [initialData, setInitialData] = useState<BiayaTambahan[] | [] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();
  useEffect(() => {
    setLoading(true);
    const url = `api/biaya/getall`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setInitialData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [trigger]);

  const [data, setData] = useState(initialData ? [...initialData] : null);
  const { sortBy, sortOrder } = useSortBiayaTambahan();
  const compareValues = useCompareValues;
  useEffect(() => {
    if (initialData) {
      const sorted = [...initialData].sort(compareValues(sortBy, sortOrder));
      setData(sorted);
    }
  }, [sortBy, sortOrder, compareValues, initialData]);

  const { searchTerm } = useSearchBiayaTambahan();
  const filteredData = data?.filter(
    (d) =>
      d.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.id.toString().includes(searchTerm) ||
      d.besaran.toString().includes(searchTerm) ||
      d.jenis.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sw = useScreenWidth();

  if (!loading && data && data.length > 0) {
    if (filteredData?.length === 0) {
      return <TidakAdaData />;
    } else {
      return (
        <>
          <Box
            borderRadius={8}
            border={"1px solid var(--divider3)"}
            overflow={"auto"}
            className="scrollX scrollY"
            maxH={sw < 770 ? "calc(100vh - 196px)" : "calc(100vh - 158px)"}
          >
            <Table variant={"striped"} colorScheme="ad">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th whiteSpace={"nowrap"} isNumeric>
                    Besaran
                  </Th>
                  <Th>Jenis</Th>
                  <Th w={"64px"}>Aksi</Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredData?.map((d, i) => (
                  <Tr key={i}>
                    <Td>{d.id}</Td>
                    <Td whiteSpace={"nowrap"}>{d.name}</Td>
                    <Td whiteSpace={"nowrap"} isNumeric>
                      <Text>
                        {formatNumber(d.besaran)}
                        {d.jenis === "presentase" ? "%" : ""}
                      </Text>
                    </Td>
                    <Td whiteSpace={"nowrap"}>
                      <Text>{d.jenis} </Text>
                    </Td>
                    <Td textAlign={"center"}>
                      <AksiUpdateDeleteBiayaTambahan data={d} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </>
      );
    }
  } else if (!loading && data && data.length === 0) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
