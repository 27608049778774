import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  useDisclosure,
  Link as ChakraLink,
  ModalFooter,
  Spinner,
  VStack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useFormatNumber from "../lib/useFormatNumber";
import DividerFullHorizontal from "./DividerFullHorizontal";
import { CaretDown } from "@phosphor-icons/react";
import RequiredForm from "./RequiredForm";
import * as yup from "yup";
import { useFormik } from "formik";
import { checkBoxTextMt } from "../const/sizes";
import useBackOnClose from "../lib/useBackOnClose";
import FileInput from "./FileInput";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

export default function UnggahBerkasLanjutan(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);
  const noreg = props?.noreg;
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const [data, setData] = useState<any>(null);
  useEffect(() => {
    const url = `api/transaction/9/getrincianbiaya`;
    if (isOpen) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            if (response.data.status === 200) {
              setData(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [isOpen]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      berkasKontrak: [] as File[] | null,
      buktiPembayaran: [] as File[] | null,
      agreement: false,
    },

    validationSchema: yup.object().shape({
      berkasKontrak: yup
        .array()
        .min(1, "Berkas Kontrak yang sudah ditanadatangani harus diunggah")
        .required("Berkas Kontrak yang sudah ditanadatangani harus diunggah")
        .test(
          "fileType",
          "Hanya file PDF, JPG, PNG yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "application/pdf" ||
                file.type === "image/jpeg" ||
                file.type === "image/png"
            )
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      buktiPembayaran: yup
        .array()
        .min(1, "Bukti Pembayaran harus diunggah")
        .required("Bukti Pembayaran harus diunggah")
        .test(
          "fileType",
          "Hanya file PDF, JPG, PNG yang diperbolehkan",
          (value) =>
            value.every(
              (file) =>
                file.type === "application/pdf" ||
                file.type === "image/jpeg" ||
                file.type === "image/png"
            )
        )
        .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
          value.every((file) => file.size <= 1000000)
        ),
      agreement: yup
        .boolean()
        .oneOf([true], "Anda harus menyetujui Syarat dan Ketentuan")
        .required("Anda harus menyetujui Syarat dan Ketentuan"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaction/${noreg}/berkaskontrak`;
      const payload = new FormData();
      if (values.berkasKontrak) {
        values.berkasKontrak.forEach((file) => {
          payload.append("kontrak", file);
        });
      }
      if (values.buktiPembayaran) {
        values.buktiPembayaran.forEach((file) => {
          payload.append("pembayaran", file);
        });
      }
      payload.append("disclaimer", values.agreement ? "1" : "0");

      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              onClose();
              setTrigger(!trigger);
              toast({
                title: "Berkas Lanjutan Berhasil Dikirim",
                description:
                  "Mohon tunggu admin untuk verifikasi berkas lanjutan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            toast({
              title: "Maaf terjadi kesalahan",
              description: error?.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  const fn = useFormatNumber;

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  return (
    <>
      <Button
        w={"100%"}
        colorScheme={"ap"}
        className="lg-clicky"
        onClick={onOpen}
      >
        Unggah Berkas Lanjutan
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        size={"lg"}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Tagihan Pengujian</ModalHeader>

          <ModalBody>
            <Alert
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <Box w={"100%"}>
                <AlertTitle
                  color={useColorModeValue("blue.500", "blue.200")}
                  mb={2}
                  fontSize={[18, null, 20]}
                  fontWeight={600}
                >
                  Unduh Berkas Kontrak Anda Disini
                </AlertTitle>

                {/* <AlertDescription>
                  <Text mb={4}>
                    Silahkan klik tombol dibawah ini untuk mengunduh berkas
                    kontrak anda.
                  </Text>
                </AlertDescription> */}

                <Button colorScheme="blue" className="lg-clicky">
                  Unduh Berkas
                </Button>
              </Box>
            </Alert>

            <Alert
              variant={"left-accent"}
              mb={4}
              alignItems={"flex-start"}
              minW={"100% !important"}
              borderRadius={8}
            >
              <Box w={"100%"}>
                <AlertTitle
                  color={useColorModeValue("blue.500", "blue.200")}
                  mb={2}
                  fontSize={[18, null, 20]}
                  fontWeight={600}
                >
                  Tagihan Pembayaran Anda!!
                </AlertTitle>

                <AlertDescription>
                  {data ? (
                    <>
                      {Object.keys(data).map((key, i) => {
                        if (key !== "Total Tagihan") {
                          return (
                            <HStack key={i} justify={"space-between"} mb={1}>
                              <Text fontSize={[12, null, 14]} opacity={0.5}>
                                {key}
                              </Text>
                              <Text fontWeight={600}>Rp {fn(data[key])}</Text>
                            </HStack>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <DividerFullHorizontal my={2} />

                      <HStack justify={"space-between"}>
                        <Text fontSize={[12, null, 14]} opacity={0.5}>
                          Total Tagihan
                        </Text>
                        <Text fontWeight={600} fontSize={[18, null, 20]}>
                          Rp {fn(data["Total Tagihan"])}
                        </Text>
                      </HStack>
                    </>
                  ) : (
                    <VStack justify={"center"} h={"100px"}>
                      <Spinner />
                    </VStack>
                  )}
                </AlertDescription>
              </Box>
            </Alert>

            <Accordion mb={4} allowMultiple>
              <AccordionItem className="accordionItem">
                <AccordionButton
                  p={4}
                  borderRadius={16}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  onClick={() => {
                    if (accordionIsOpen) {
                      setAccordionIsOpen(false);
                    } else {
                      setAccordionIsOpen(true);
                    }
                  }}
                >
                  <Text
                    fontSize={[16, null, 18]}
                    fontWeight={600}
                    textAlign={"left"}
                    mr={8}
                  >
                    Tata Cara Pembayaran Tagihan Pengujian
                  </Text>
                  <Center
                    bg={accordionIsOpen ? "p.500" : "var(--divider)"}
                    borderRadius={"full"}
                    p={2}
                    alignSelf={"flex-start"}
                  >
                    <Icon
                      as={CaretDown}
                      fontSize={[18, null, 20]}
                      weight="bold"
                      color={accordionIsOpen ? "white" : "p.500"}
                      transform={accordionIsOpen ? "rotate(-180deg)" : ""}
                      transition={"200ms"}
                    />
                  </Center>
                </AccordionButton>

                <AccordionPanel p={4} pt={2}>
                  <OrderedList>
                    <ListItem mb={2} mt={2}>
                      <b>Akses Akun</b> : Login ke akun perbankan Anda.
                    </ListItem>
                    <ListItem mb={2}>
                      <b> Pilih Pembayaran</b> : Klik opsi "Pembayaran" atau
                      "Transfer."
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Pilih Rekening Sumber</b> : Pilih rekening asal.
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Masukan Rekening atau BRIVA Poltekkes</b> : BRI
                      123xxxxxx, VA: 123xxxxxxxx.
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Isi Jumlah</b> : Masukkan jumlah pembayaran.
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Konfirmasi Detail</b> : Periksa dan konfirmasi detail
                      transaksi.
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Otorisasi</b> : Klik "Kirim" atau "Lanjutkan" untuk
                      menyelesaikan pembayaran.
                    </ListItem>
                    <ListItem mb={2}>
                      <b>Konfirmasi Sukses</b> : Dapatkan pesan konfirmasi.
                    </ListItem>
                    <ListItem>
                      <b>Simpan Bukti Transfers</b> dan{" "}
                      <b>Upload bukti pembayaran</b>
                      di bawah ini
                    </ListItem>
                  </OrderedList>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <form id="buktiPembayaranForm" onSubmit={formik.handleSubmit}>
              <FormControl
                mb={4}
                isInvalid={formik.errors.berkasKontrak ? true : false}
              >
                <FormLabel>
                  Unggah Berkas Kontrak
                  <RequiredForm />
                </FormLabel>
                <FileInput formik={formik} name="berkasKontrak" />
                <FormErrorMessage>
                  {formik.errors.berkasKontrak}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.buktiPembayaran ? true : false}
              >
                <FormLabel>
                  Unggah Bukti Pembayaran
                  <RequiredForm />
                </FormLabel>
                <FileInput formik={formik} name="buktiPembayaran" />
                <FormErrorMessage>
                  {formik.errors.buktiPembayaran}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.agreement ? true : false}>
                <Checkbox
                  alignItems={"flex-start"}
                  colorScheme="ap"
                  gap={1}
                  isChecked={formik.values.agreement}
                  isInvalid={formik.errors.agreement ? true : false}
                  onChange={() => {
                    formik.setFieldValue("agreement", !formik.values.agreement);
                  }}
                >
                  <Text mt={checkBoxTextMt} fontSize={[12, null, 14]}>
                    Saya dengan ini menyatakan setuju dengan{" "}
                    <ChakraLink
                      color="p.500"
                      href="/syarat-dan-ketentuan"
                      fontSize={["12px !important", null, "14px !important"]}
                      isExternal
                      fontWeight={600}
                    >
                      syarat dan ketentuan
                    </ChakraLink>{" "}
                    yang berlaku di LPPT POLTEKKES Kemenkes Semarang
                  </Text>
                </Checkbox>
                <FormErrorMessage>{formik.errors.agreement}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="ap"
              type="submit"
              form="buktiPembayaranForm"
              className="lg-clicky btn-ap"
              isDisabled={!formik.values.agreement}
              isLoading={loading}
            >
              Simpan & Lanjutkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
