import {
  Box,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckCircle, WarningCircle, XCircle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import { CustomerStats } from "../const/types";

export default function CustomerStatsComponent() {
  const [data, setData] = useState<CustomerStats | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = `api/transaction/getcount`;

    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((r) => {
          if (r.status === 200) {
            setData(r.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  // sx
  const iconOpacity = useColorModeValue(0.5, 0.2);

  return !loading && data ? (
    <>
      <SimpleGrid columns={[1, null, 2]} gap={4} mb={4}>
        <Box
          position={"relative"}
          borderRadius={16}
          p={[4, 6, 6]}
          bg={"var(--p500a)"}
          overflow={"clip"}
        >
          <Icon
            as={CheckCircle}
            fontSize={150}
            position={"absolute"}
            bottom={-10}
            right={-10}
            opacity={iconOpacity}
            color={"white"}
          />

          <HStack mb={4} gap={3} justify={"space-between"}>
            <Text fontWeight={500}>Selesai</Text>
          </HStack>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            {data.success}
          </Text>
        </Box>

        <Box
          position={"relative"}
          overflow={"clip"}
          borderRadius={16}
          p={[4, 6, 6]}
          bg={"var(--orangea)"}
        >
          <Icon
            as={WarningCircle}
            fontSize={150}
            position={"absolute"}
            bottom={-10}
            right={-10}
            opacity={iconOpacity}
            color={"white"}
          />

          <HStack mb={4} gap={3} justify={"space-between"}>
            <Text fontWeight={500}>Dalam Proses</Text>
          </HStack>

          <Text fontSize={[22, null, 24]} fontWeight={700}>
            {data.process}
          </Text>
        </Box>
      </SimpleGrid>

      <Box
        position={"relative"}
        overflow={"clip"}
        borderRadius={16}
        p={[4, 6, 6]}
        bg={"var(--reda)"}
      >
        <Icon
          as={XCircle}
          fontSize={150}
          position={"absolute"}
          bottom={-10}
          right={-10}
          opacity={iconOpacity}
          color={"white"}
        />

        <HStack mb={4} gap={3} justify={"space-between"}>
          <Text fontWeight={500}>Berkas Ditolak</Text>
        </HStack>

        <Text fontSize={[22, null, 24]} fontWeight={700}>
          {data.fail}
        </Text>
      </Box>
    </>
  ) : (
    <ComponentSpinner />
  );
}
