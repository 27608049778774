import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useCompareValues from "../lib/useCompareValues";
import useSortCustomer from "../globalState/useSortCustomer";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import PaginationNav from "./PaginationNav";
import TidakAdaData from "./TidakAdaData";
import ComponentSpinner from "./ComponentSpinner";
import { Customer } from "../const/types";
import NoOflinesText from "./NoOflinesText";
import formatDate from "../lib/formatDate";
import useScreenWidth from "../lib/useGetScreenWidth";
import useSearchCustomer from "../globalState/useSearchCustomer";

export default function TabelCustomer() {
  const [initialData, setInitialData] = useState<Customer[] | null>(null);
  const [pagination, setPagination] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { searchTerm } = useSearchCustomer();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!searchTerm) {
      setLoading(true);
      const url = `api/customer/getall?page=${page}`;
      const payload = { limit: limit };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setInitialData(response.data.data.data);
              setPagination(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [limit, page, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);
      const url = `api/customer/search`;
      const payload = { pencarian: searchTerm };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setInitialData(response.data.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [searchTerm]);

  const [data, setData] = useState(initialData ? [...initialData] : null);
  const { sortBy, sortOrder } = useSortCustomer();
  const compareValues = useCompareValues;
  useEffect(() => {
    if (initialData) {
      const sorted = [...initialData].sort(compareValues(sortBy, sortOrder));
      setData(sorted);
    }
  }, [sortBy, sortOrder, compareValues, initialData]);

  const sw = useScreenWidth();

  if (!loading && data && data.length > 0) {
    return (
      <>
        <Box
          borderRadius={8}
          border={"1px solid var(--divider3)"}
          overflow={"auto"}
          className="scrollX"
          maxH={sw < 770 ? "calc(100vh - 296px)" : "calc(100vh - 256px)"}
        >
          <Table variant={"striped"} colorScheme="ad">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th whiteSpace={"nowrap"}>Nama Lengkap</Th>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Nomor Ponsel</Th>
                <Th>Instansi/Perusahaan</Th>
                <Th>Alamat</Th>
                <Th>Dibuat</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.map((d, i) => (
                <Tr key={i}>
                  <Td>{d.id}</Td>
                  <Td whiteSpace={"nowrap"}>{d.name}</Td>
                  <Td>{d.username}</Td>
                  <Td>{d.email}</Td>
                  <Td>{d.hp}</Td>
                  <Td whiteSpace={"nowrap"}>{d.institusi}</Td>
                  <Td>
                    <NoOflinesText w={"200px"}>
                      {d.alamat_institusi}
                    </NoOflinesText>
                  </Td>
                  <Td whiteSpace={"nowrap"}>{formatDate(d.created_at)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <PaginationNav
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          pagination={pagination}
        />
      </>
    );
  } else if (!loading && data && data.length === 0) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
