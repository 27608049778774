import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import SortKelolaProduk from "../../components/SortKelolaProduk";
import TabelKelolaProduk from "../../components/TabelKelolaProduk";
import TambahProdukModal from "../../components/TambahProdukModal";
import SearchProduk from "../../components/SearchProduk";

export default function AdminProduk() {
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Produk", "Kelola Produk"]}>
      <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
        <HStack mb={4}>
          <SearchProduk />

          <SortKelolaProduk />

          <TambahProdukModal />
        </HStack>

        <TabelKelolaProduk />
      </Box>
    </AdminContainer>
  );
}
