import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import { Plus } from "@phosphor-icons/react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

export default function TambahPengujiModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      namaLengkap: "",
      email: "",
      phone: "",
    },

    validationSchema: yup.object().shape({
      namaLengkap: yup.string().required("Nama Lengkap harus diisi"),
      email: yup
        .string()
        .email("Masukkan Email yang valid")
        .required("Email harus diisi"),
      phone: yup.string().required("Nomor Ponsel harus diisi"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values);
      setLoading(true);
      const url = `api/penguji/store`;
      const payload = {
        name: values.namaLengkap,
        email: values.email,
        hp: values.phone,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              resetForm();
              toast({
                title: "Penguji Berhasil Ditambahkan",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              setTrigger(!trigger);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        flexShrink={0}
        leftIcon={<Icon as={Plus} fontSize={iconSize} />}
        pl={3}
        pr={[1, 4]}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
      >
        <Text display={["none", "block"]}>Tambah Penguji</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Tambah Penguji</ModalHeader>

          <ModalBody>
            <form id="tambahProdukForm" onSubmit={formik.handleSubmit}>
              <FormControl
                mb={4}
                isInvalid={formik.errors.namaLengkap ? true : false}
              >
                <FormLabel>
                  Nama Lengkap
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="namaLengkap"
                  placeholder="Masukkan nama lengkap"
                  onChange={formik.handleChange}
                  value={formik.values.namaLengkap}
                />
                <FormErrorMessage>{formik.errors.namaLengkap}</FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.email ? true : false}
              >
                <FormLabel>
                  Email
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="email"
                  placeholder="Masukkan email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isInvalid={formik.errors.phone ? true : false}
              >
                <FormLabel>
                  Nomor Ponsel
                  <RequiredForm />
                </FormLabel>
                <Input
                  name="phone"
                  placeholder="Masukkan nomor ponsel"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form={"tambahProdukForm"}
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Tambahkan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
