import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useScreenWidth from "../lib/useGetScreenWidth";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";
import TidakAdaData from "./TidakAdaData";
import { FileTemplate } from "../const/types";
import useTrigger from "../globalState/useTrigger";
import UpdateFileTemplateModal from "./UpdateFileTemplateModal";
import formatDate from "../lib/formatDate";

export default function TabelFileTemplate() {
  const [data, setData] = useState<FileTemplate[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { trigger } = useTrigger();
  useEffect(() => {
    setLoading(true);
    const url = `api/file-template/get`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.data.status === 200) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [trigger]);

  const sw = useScreenWidth();

  if (!loading && data && data.length > 0) {
    return (
      <Box
        borderRadius={8}
        border={"1px solid var(--divider3)"}
        overflow={"auto"}
        className="scrollX scrollY"
        maxH={sw < 770 ? "calc(100vh - 246px)" : "calc(100vh - 200px)"}
      >
        <Table variant={"striped"} colorScheme="ad">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Label</Th>
              <Th>Nama File</Th>
              <Th>Dibuat</Th>
              <Th>Terakhir Diubah</Th>
              <Th textAlign={"center"}>Aksi</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data.map((d, i) => {
              return (
                <Tr key={i}>
                  <Td>{d.id}</Td>
                  <Td whiteSpace={"nowrap"}>{d.label}</Td>
                  <Td>{d.filename}</Td>
                  <Td whiteSpace={"nowrap"}>{formatDate(d.created_at)}</Td>
                  <Td whiteSpace={"nowrap"}>{formatDate(d.updated_at)}</Td>
                  <Td isNumeric w={"40px"}>
                    <UpdateFileTemplateModal data={d} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    );
  } else if (!loading && data && data.length === 0) {
    return <TidakAdaData />;
  } else {
    return <ComponentSpinner h={"400px"} />;
  }
}
