import { Spinner, VStack } from "@chakra-ui/react";
import React from "react";

export default function ComponentSpinner(props: any) {
  return (
    <VStack w={"100%"} h={props.h ? props.h : "200px"} justify={"center"}>
      <Spinner />
    </VStack>
  );
}
