import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import { useFormik } from "formik";
import * as yup from "yup";
import RequiredForm from "./RequiredForm";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { FileTemplate } from "../const/types";
import FileInput from "./FileInput";
import { PencilSimpleLine } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";

type Props = {
  data: FileTemplate;
};

export default function UpdateFileTemplateModal({ data }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      id: data.id,
      file: "",
    },

    validationSchema: yup.object().shape({
      file: yup.array().required("File Template harus diunggah"),
      // .test(
      //   "fileType",
      //   "Hanya file PDF, JPG, PNG yang diperbolehkan",
      //   (value) =>
      //     value.every(
      //       (file) =>
      //         file.type === "application/pdf" ||
      //         file.type === "image/jpeg" ||
      //         file.type === "image/png"
      //     )
      // )
      // .test("fileSize", "Ukuran maksimal file adalah 1 MB", (value) =>
      //   value.every((file) => file.size <= 1000000)
      // ),
    }),

    onSubmit: (values) => {
      setLoading(true);
      const url = `api/file-template/update`;
      const payload = {
        file: values.file,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              toast({
                title: "File Template Berhasil Diubah",
                status: "success",
                duration: 10000,
                isClosable: true,
              });
              onClose();
            } else {
              toast({
                title: "File Template Gagal Dihapus",
                description:
                  response.data.message.file[0] || "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        onClick={onOpen}
        rightIcon={<Icon as={PencilSimpleLine} fontSize={iconSize} />}
        className="btn-solid"
      >
        Ubah
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose} size={"lg"}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Ubah File Template</ModalHeader>

          <ModalBody>
            <form id="updateForm" onSubmit={formik.handleSubmit}>
              <FormControl mb={4} isInvalid={formik.errors.file ? true : false}>
                <FormLabel>
                  File Template
                  <RequiredForm />
                </FormLabel>
                <FileInput name="file" formik={formik} />
                <FormErrorMessage>{formik.errors.file}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="updateForm"
              colorScheme="ap"
              className="lg-clicky btn-ap"
              isLoading={loading}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
