import {
  Avatar,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useScreenWidth from "../lib/useGetScreenWidth";
import customerNav from "../const/customerNav";
import { Link, useNavigate } from "react-router-dom";
import LandingHeader from "./LandingHeader";
import CustomerNavDrawer from "./CustomerNavDrawer";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { CaretDown, SignOut } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import SedangKeluar from "./SedangKeluar";
import { getCookie } from "typescript-cookie";
import { UserData } from "../const/types";

type Props = { active: string };

export default function CustomerNav({ active }: Props) {
  const sw = useScreenWidth();

  const buttonBg = useColorModeValue("red.500", "red.200");
  const buttonBgHover = useColorModeValue("red.600", "red.300");
  const buttonBgActive = useColorModeValue("red.700", "red.400");
  const buttonColor = useColorModeValue("white", "black");

  const [loadingKeluar, setLoadingKeluar] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [userData, setUserData] = useState<UserData | null>(null);
  useEffect(() => {
    const userDataCookie = getCookie("userData");
    if (userDataCookie) {
      setUserData(JSON.parse(userDataCookie));
    }
  }, []);

  const handleSignout = () => {
    setLoadingKeluar(true);
    const url = `api/logout`;
    clientRequest.then(() => {
      reqWithHeader
        .post(url)
        .then((response) => {
          if (response.data.status === 200) {
            navigate("/");
            toast({
              title: "Berhasil Keluar",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {loadingKeluar && <SedangKeluar />}

      <HStack
        id="customerNav"
        justify={"space-between"}
        h={["80px", null, null]}
      >
        <HStack gap={8}>
          <LandingHeader />

          <HStack gap={0}>
            {sw >= 768 &&
              customerNav.map((n, i) => (
                <Button
                  key={i}
                  as={Link}
                  to={n.link}
                  variant={"ghost"}
                  h={"80px"}
                  borderBottom={
                    active === n.name ? "2px solid var(--p500)" : ""
                  }
                  className="btn"
                  borderRadius={"0"}
                  color={active === n.name ? "p.500" : "current"}
                >
                  <Text>{n.name}</Text>
                </Button>
              ))}
          </HStack>
        </HStack>

        {sw >= 768 && (
          <HStack>
            <ColorModeSwitcher />

            <Menu>
              <MenuButton
                px={0}
                as={HStack}
                // _hover={{ bg: "divider" }}
                borderRadius={8}
                p={2}
                cursor={"pointer"}
              >
                <HStack>
                  <Icon as={CaretDown} />
                  <Avatar
                    size={"md"}
                    name={userData?.name}
                    bg={"p.500"}
                    color={"white"}
                    // src="/images/user.jpg"
                  />
                </HStack>
              </MenuButton>

              <MenuList minW={"126px"} border={"none"}>
                <MenuItem
                  bg={buttonBg}
                  color={buttonColor}
                  _hover={{ bg: buttonBgHover }}
                  _active={{ bg: buttonBgActive }}
                  onClick={handleSignout}
                >
                  <HStack>
                    <Icon as={SignOut} fontSize={iconSize} />
                    <Text>Keluar</Text>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        )}

        {sw < 768 && <CustomerNavDrawer active={active} />}
      </HStack>
    </>
  );
}
