import { SimpleGrid, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useFormatNumber from "../lib/useFormatNumber";
import { useParams } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { TransactionSummary } from "../const/types";
import ComponentSpinner from "./ComponentSpinner";

export default function DetailSummary() {
  const totalTagihan = 1800000;
  const fn = useFormatNumber;

  const [data, setData] = useState<TransactionSummary | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { noreg } = useParams();

  useEffect(() => {
    setLoading(true);
    const url = `api/transaksi/${noreg}/summary`;
    if (noreg) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [noreg]);

  if (!loading && data) {
    return (
      <SimpleGrid
        columns={[1, 2, 4]}
        bg={"var(--p500a)"}
        borderRadius={8}
        p={4}
        gap={4}
      >
        <VStack gap={1} align={["flex-start", "center"]}>
          <Text fontSize={[12, null, 14]} opacity={0.5}>
            Total Metode Uji
          </Text>
          <Text fontWeight={600}>{data.total_metode} Metode uji</Text>
        </VStack>

        <VStack gap={1} align={["flex-start", "center"]}>
          <Text fontSize={[12, null, 14]} opacity={0.5}>
            Total Foto Sampel
          </Text>
          <Text fontWeight={600}>{data.berkasutama} Foto Sampel</Text>
        </VStack>

        <VStack gap={1} align={["flex-start", "center"]}>
          <Text fontSize={[12, null, 14]} opacity={0.5}>
            Total File Pendukung
          </Text>
          <Text fontWeight={600}>{data.berkaspendukung} File Pendukung</Text>
        </VStack>

        <VStack gap={1} align={["flex-start", "center"]}>
          <Text fontSize={[12, null, 14]} opacity={0.5}>
            Total Metode Uji
          </Text>
          <Text fontWeight={600}>Rp {fn(totalTagihan)}</Text>
        </VStack>
      </SimpleGrid>
    );
  } else {
    return <ComponentSpinner />;
  }
}
