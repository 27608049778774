import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { List, X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { iconSize } from "../const/sizes";
import customerNav from "../const/customerNav";
import { Link, useNavigate } from "react-router-dom";
import useBackOnClose from "../lib/useBackOnClose";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import SedangKeluar from "./SedangKeluar";
import getUserData from "../lib/userData";

type Props = {
  active: string;
};

export default function CustomerNavDrawer({ active }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = getUserData();

  useBackOnClose(isOpen, onClose);

  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const [loadingKeluar, setLoadingKeluar] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSignout = () => {
    setLoadingKeluar(true);
    const url = `api/logout`;
    clientRequest.then(() => {
      reqWithHeader
        .post(url)
        .then((response) => {
          if (response.data.status === 200) {
            navigate("/");
            toast({
              title: "Berhasil Keluar",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {loadingKeluar && <SedangKeluar />}

      <IconButton
        aria-label="menu"
        icon={<Icon as={List} fontSize={iconSize} />}
        variant={"outline"}
        colorScheme="ap"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={handleOnClose}>
        <DrawerOverlay />

        <DrawerContent>
          <IconButton
            aria-label="close nav drawer"
            icon={<Icon as={X} fontSize={iconSize} />}
            borderRadius={"full"}
            position={"absolute"}
            left={-5}
            top={"calc(50% - 20px)"}
            zIndex={99}
            bg={"white"}
            color={"black"}
            _hover={{ bg: "white" }}
            _active={{ bg: "white" }}
            className="clicky"
            onClick={handleOnClose}
          />

          <DrawerBody
            as={VStack}
            gap={0}
            align={"stretch"}
            p={0}
            position={"relative"}
            bg={"p.900"}
            backdropFilter={"blur(10px)"}
            py={5}
          >
            <Image
              src={"/images/navDrawerBg.png"}
              w={"100%"}
              position={"absolute"}
              bottom={0}
              opacity={0.1}
            />

            <VStack gap={0} position={"relative"}>
              <ColorModeSwitcher position={"absolute"} left={0} top={-3} />

              <Image src="/logo192.png" w={"48px"} mb={2} />

              <Text fontWeight={700} lineHeight={1}>
                POLTEKKES
              </Text>
              <Text fontWeight={500}>Kemenkes Semarang</Text>
            </VStack>

            <VStack
              zIndex={2}
              gap={0}
              borderRadius={16}
              py={6}
              px={10}
              align={"stretch"}
              flex={1}
            >
              <HStack p={2} mb={6} bg={"var(--divider)"} borderRadius={8}>
                <Avatar
                  name={userData.name}
                  bg={"var(--divider3)"}
                  color={"white"}
                />

                <Box>
                  <Text fontWeight={600} lineHeight={1} mb={1}>
                    {userData.name}
                  </Text>
                  <Text noOfLines={1} lineHeight={1} fontSize={[12, null, 14]}>
                    {userData.email}
                  </Text>
                </Box>
              </HStack>

              <VStack w={"100%"} align={"stretch"} flex={1}>
                <Text
                  fontWeight={500}
                  opacity={0.5}
                  ml={1}
                  fontSize={[12, null, 14]}
                >
                  NAVIGASI
                </Text>
                {customerNav.map((n, i) => (
                  <Button
                    key={i}
                    as={Link}
                    to={n.link}
                    variant={"ghost"}
                    bg={active === n.name ? "blackAlpha.200" : ""}
                    className="btn"
                    borderRadius={8}
                    color={"white"}
                  >
                    <HStack w={"100%"}>
                      <Text fontWeight={500}>{n.name}</Text>
                    </HStack>
                  </Button>
                ))}
              </VStack>

              <Button
                colorScheme="ap"
                variant={"outline"}
                onClick={handleSignout}
                isLoading={loadingKeluar}
              >
                Keluar
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
