import {
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { CaretDown, CaretLeft, CaretRight } from "@phosphor-icons/react";
import React from "react";
import { iconSize } from "../const/sizes";

type Props = {
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  pagination: any;
};

export default function PaginationNav({
  limit,
  setLimit,
  page,
  setPage,
  pagination,
}: Props) {
  // props setLimit, page, setPage, pagination
  return (
    <Stack
      flexDir={"row"}
      justify={"space-between"}
      align={"center"}
      mt={4}
      gap={4}
    >
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={CaretDown} fontSize={iconSize} />}
          className="btn-solid"
        >
          <HStack>
            <Text>Show</Text>
            <Text color={"p.500"} fontWeight={600}>
              {limit}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList minW={"125px"}>
          <MenuItem
            onClick={() => {
              setLimit(10);
              setPage(1);
            }}
          >
            10
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLimit(50);
              setPage(1);
            }}
          >
            50
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLimit(100);
              setPage(1);
            }}
          >
            100
          </MenuItem>
        </MenuList>
      </Menu>

      <HStack justify={"center"}>
        <IconButton
          aria-label="prev"
          icon={<Icon as={CaretLeft} fontSize={iconSize} />}
          variant={"ghost"}
          className="btn"
          size={"sm"}
          onClick={() => {
            setPage(page - 1);
          }}
          isDisabled={!pagination.prev_page_url}
        />

        {/* <HStack gap={1}>
          {pagination.links.map((link: any, i: number) => {
            const currentPage = pagination.current_page;
            const label = parseInt(link.label);
            // Check if the link should be rendered based on the current page
            const shouldRenderLink =
              label === currentPage || // Current page
              label === 1 || // First page
              label === pagination.last_page || // Last page
              (label >= currentPage - 1 && label <= currentPage + 1); // Pages within 2 of current page

            if (shouldRenderLink) {
              return (
                <Center
                  key={i}
                  bg={label === currentPage ? "p.500" : ""}
                  w={"32px"}
                  h={"32px"}
                  borderRadius={8}
                  cursor={"pointer"}
                  _hover={{ bg: "var(--divider)" }}
                  transition={"200ms"}
                  onClick={() => {
                    setPage(label);
                  }}
                >
                  <Text color={"white"}>{link.label}</Text>
                </Center>
              );
            } else if (
              // Render ellipsis for skipped pages
              (label === currentPage - 2 && currentPage > 3) ||
              (label === currentPage + 2 &&
                currentPage < pagination.last_page - 1)
            ) {
              return (
                <Center key={i} w={"12px"} h={"32px"} borderRadius={8}>
                  <Text>...</Text>
                </Center>
              );
            } else {
              return null; // Do not render the link
            }
          })}
        </HStack> */}

        <Center
          bg={page === pagination.current_page ? "p.500" : ""}
          w={"32px"}
          h={"32px"}
          borderRadius={8}
          cursor={"pointer"}
          transition={"200ms"}
        >
          <Text color={"white"}>{page}</Text>
        </Center>

        <IconButton
          aria-label="prev"
          icon={<Icon as={CaretRight} fontSize={iconSize} />}
          variant={"ghost"}
          className="btn"
          size={"sm"}
          onClick={() => {
            setPage(page + 1);
          }}
          isDisabled={!pagination.next_page_url}
        />
      </HStack>
    </Stack>
  );
}
