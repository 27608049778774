import {
  Button,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import useBackOnClose from "../lib/useBackOnClose";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

type Props = {
  noreg: number;
};

export default function VerifikasiBerkasModal({ noreg }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  const tolakColor = useColorModeValue("red.500", "red.200");

  const [loading, setLoading] = useState<boolean>(false);
  const { trigger, setTrigger } = useTrigger();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      verifikasi: "",
      keterangan: "",
    },

    validationSchema: yup.object().shape({
      verifikasi: yup.string().required("Pilih aksi untuk verifikasi berkas"),
    }),

    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const url = `api/transaction/${noreg}/verifikasiberkas`;
      const payload = {
        verifikasi: values.verifikasi,
        keterangan: values.keterangan,
      };
      clientRequest.then(() => {
        reqWithHeader
          .post(url, payload)
          .then((response) => {
            if (response.data.status === 200) {
              setTrigger(!trigger);
              toast.closeAll();
              toast({
                title: response.data.message,
                description: response.data.data.respon,
                status: "success",
                duration: 10000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Verifikasi Gagal",
                description: "Maaf terjadi kesalahan",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    },
  });

  return (
    <>
      <Button
        w={"100%"}
        colorScheme="ap"
        className="lg-clicky btn-ap"
        onClick={onOpen}
        id={`Verifikasi Berkas - ${noreg}`}
      >
        Verifikasi Berkas
      </Button>

      <Modal isOpen={isOpen} onClose={handleOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>Verifikasi Berkas</ModalHeader>

          <ModalBody>
            <form id="verifikasiBerkasForm" onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.errors.verifikasi ? true : false}>
                <RadioGroup
                  name="verifikasi"
                  onChange={(value) => {
                    formik.setFieldValue("verifikasi", value);
                  }}
                  value={formik.values.verifikasi}
                  mb={4}
                >
                  <VStack align={"flex-start"} gap={4}>
                    <Radio
                      size={"lg"}
                      colorScheme="ap"
                      value={"Berkas Diverifikasi"}
                      color={"p.500"}
                    >
                      <Text color={"p.500"}>Verifikasi</Text>
                    </Radio>

                    <Radio
                      size={"lg"}
                      colorScheme="ap"
                      value={"Verifikasi Ditolak"}
                    >
                      <Text color={tolakColor}>Tolak</Text>
                    </Radio>
                  </VStack>
                </RadioGroup>

                <Textarea
                  name="keterangan"
                  onChange={formik.handleChange}
                  placeholder="Masukkan keterangan (opsional)"
                  isDisabled={
                    formik.values.verifikasi === "Verifikasi Ditolak"
                      ? false
                      : true
                  }
                />

                <FormErrorMessage>{formik.errors.verifikasi}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              form="verifikasiBerkasForm"
              className="lg-clicky btn-ap"
              colorScheme="ap"
              isLoading={loading}
            >
              Simpan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
