import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { HStack, VStack, useColorModeValue } from "@chakra-ui/react";
import SortCustomer from "../../components/SortCustomer";
import TabelCustomer from "../../components/TabelCustomer";
import SearchCustomer from "../../components/SearchCustomer";

export default function AdminCustomer() {
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Pengguna", "Customer"]}>
      <VStack
        w={"100%"}
        align={"stretch"}
        gap={0}
        p={[4, null, 6]}
        bg={bg}
        borderRadius={16}
        mb={6}
      >
        <HStack mb={4}>
          <SearchCustomer />

          <SortCustomer />
        </HStack>

        <TabelCustomer />
      </VStack>
    </AdminContainer>
  );
}
