import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { File } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import useBackOnClose from "../lib/useBackOnClose";
import ComponentSpinner from "./ComponentSpinner";
import { Transaksi } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import formatDate from "../lib/formatDate";
import axios from "axios";

type Props = {
  noreg: number;
};

export default function DetailTransaksiModal({ noreg }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useBackOnClose(isOpen, onClose);
  const handleOnClose = () => {
    onClose();
    window.history.back();
  };

  // console.log(noreg);
  const [data, setData] = useState<Transaksi | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    setLoading(true);

    const url = `api/transaction/${noreg}/getdetail`;
    if (isOpen) {
      clientRequest.then(() => {
        reqWithHeader
          .get(url)
          .then((response) => {
            // console.log(response.data);
            setData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => [setLoading(false)]);
      });
    }
  }, [isOpen, noreg]);

  const handleUnduhSemuaBerkas = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/all`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            console.log(r);
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Semua Berkas (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleUnduhFotoSampel = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkassampel`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Sampel (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleUnduhFilePendukung = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkaspendukung`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Pendukung (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleUnduhBerkasKontrak = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkaskontrak`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Kontrak (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  const handleUnduhBuktiBayar = () => {
    const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/berkasbukti`;
    clientRequest.then(() => {
      axios
        .get(url, {
          responseType: "blob",
          withCredentials: true,
          xsrfCookieName: "XSRF-TOKEN",
          xsrfHeaderName: "X-XSRF-TOKEN",
        })
        .then((r) => {
          if (r.status === 200) {
            const downloadUrl = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute(
              "download",
              `Berkas Bukti Bayar (${data?.nama_sertifikat}).${r.data.type
                .split("/")
                .pop()}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Gagal mengunduh",
            description: e?.response?.data?.message,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        });
    });
  };

  return (
    <>
      <Button
        colorScheme="ap"
        variant={"outline"}
        className="lg-clicky"
        onClick={onOpen}
      >
        Detail
      </Button>

      <Modal
        isOpen={isOpen}
        size={"lg"}
        onClose={handleOnClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent maxW={"650px"}>
          <ModalCloseButton />

          <ModalHeader>Detail Transaksi</ModalHeader>

          {!loading && data ? (
            <ModalBody>
              <a href="/images/logoDark.png" download={"logo poltekkes.png"}>
                donlod logo poltekkes
              </a>
              {data.status === "Verifikasi Berkas Ditolak" && (
                <Alert
                  className="alert"
                  variant={"left-accent"}
                  colorScheme="red"
                  mb={6}
                >
                  <Box>
                    <AlertTitle fontSize={[18, null, 20]} color={"red"}>
                      Berkas Ditolak
                    </AlertTitle>
                    <AlertDescription>{data.alasan_tolak}</AlertDescription>
                  </Box>
                </Alert>
              )}

              {data.status === "Veridikasi Berkas Lanjutan Ditolak" && (
                <Alert
                  className="alert"
                  variant={"left-accent"}
                  colorScheme="red"
                  mb={6}
                >
                  <Box>
                    <AlertTitle fontSize={[18, null, 20]} color={"red"}>
                      Berkas Lanjutan Ditolak
                    </AlertTitle>
                    <AlertDescription>
                      {data.alasan_tolak_kontrak}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}

              <Stack
                flexDir={["column", null, "row"]}
                gap={4}
                className="detailTransaksiItem"
              >
                <Text className="detailTransaksiItemLabel">No. Registrasi</Text>

                <Box>
                  <Text fontWeight={500}>
                    {noreg.toString().padStart(3, "0")}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Tanggal Transaksi
                </Text>

                <Box>
                  <Text fontWeight={500}>{formatDate(data.tgl_transaksi)}</Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Nama Sertifikat
                </Text>

                <Box>
                  <Text fontWeight={500}>{data.nama_sertifikat}</Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Metode Uji</Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.product?.name ? 1 : 0.5}
                  >
                    {data?.product?.name || "Metode Uji belum dipilih"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Tanggal Pengujian
                </Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.tgl_pengujian ? 1 : 0.5}
                  >
                    {data?.tgl_pengujian ||
                      "Saat ini belum memiliki Tanggal Pengujian"}
                  </Text>
                </Box>
              </Stack>

              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Penguji</Text>
                <Box>
                  <Text
                    fontWeight={500}
                    opacity={data?.penguji?.nama ? 1 : 0.5}
                  >
                    {data?.penguji
                      ? `${data?.penguji?.nama} (${data?.penguji?.id})`
                      : "Saat ini belum memiliki Penguji"}
                  </Text>
                </Box>
              </Stack>

              {/* Foto Sampel */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">Foto Sampel</Text>

                {data.berkasutama.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhFotoSampel}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      {data.berkasutama.map((d, i) => (
                        <VStack
                          key={i}
                          gap={0}
                          borderRadius={8}
                          bg={"var(--divider)"}
                          p={2}
                          border={"1px solid var(--divider3)"}
                          mb={2}
                          align={"stretch"}
                        >
                          <Image
                            src={`${process.env.REACT_APP_VPS_DOMAIN}${d.path}`}
                            borderRadius={8}
                            w={"100%"}
                            h={"180px"}
                            objectFit={"cover"}
                            mb={2}
                          />
                          <Box px={2}>
                            <Text noOfLines={1} fontSize={[12, null, 14]}>
                              {d.name}
                            </Text>
                            <Text opacity={0.5} fontSize={[12, null, 14]}>
                              {d.keterangan}
                            </Text>
                          </Box>
                        </VStack>
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>Saat ini belum memiliki Foto Sampel</Text>
                )}
              </Stack>

              {/* FIle Pendukung */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">File Pendukung</Text>

                {data.berkaspendukung.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhFilePendukung}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      {data.berkaspendukung.map((d, i) => (
                        <Box key={i}>
                          <VStack
                            gap={0}
                            borderRadius={8}
                            bg={"var(--divider)"}
                            p={2}
                            border={"1px solid var(--divider3)"}
                            mb={2}
                            align={"flex-start"}
                          >
                            <Icon
                              as={File}
                              fontSize={100}
                              weight="fill"
                              mb={4}
                              mt={2}
                            />
                            <Box px={2} w={"100%"}>
                              <Text fontSize={[12, null, 14]} noOfLines={1}>
                                {d.name}
                              </Text>
                              <Text
                                fontSize={[12, null, 14]}
                                opacity={0.5}
                                noOfLines={1}
                              >
                                {d.size}
                              </Text>
                              <Text opacity={0.5} fontSize={[12, null, 14]}>
                                {d.keterangan}
                              </Text>
                            </Box>
                          </VStack>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat ini belum memiliki File Pendukung
                  </Text>
                )}
              </Stack>

              {/* File Kontrak */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">File Kontrak</Text>

                {data.berkaskontrakraw.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhBerkasKontrak}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1, 2]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                        align={"flex-start"}
                      >
                        <Icon
                          as={File}
                          fontSize={[50, null, 70]}
                          weight="light"
                          mb={2}
                        />
                        <Box px={2}>
                          <Text fontSize={[12, null, 14]}>Berkas Kontrak</Text>
                          <Text fontSize={[12, null, 14]} opacity={0.5}>
                            {data.berkaskontrakraw[0].size}
                          </Text>
                        </Box>
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat Ini Belum memiliki Berkas Kontrak
                  </Text>
                )}
              </Stack>

              {/* Bukti Pembayaran */}
              <Stack
                className="detailTransaksiItem"
                flexDir={["column", null, "row"]}
                gap={4}
              >
                <Text className="detailTransaksiItemLabel">
                  Bukti Pembayaran
                </Text>

                {data.berkasbuktipembayaran.length > 0 ? (
                  <Box w={"100%"}>
                    <Button
                      colorScheme="ap"
                      className="lg-clicky btn-ap"
                      mb={3}
                      onClick={handleUnduhBuktiBayar}
                    >
                      Unduh
                    </Button>

                    <SimpleGrid columns={[1]} gap={4}>
                      <VStack
                        gap={0}
                        borderRadius={8}
                        bg={"var(--divider)"}
                        p={2}
                        border={"1px solid var(--divider3)"}
                      >
                        <Image
                          src={`${process.env.REACT_APP_VPS_DOMAIN}${data.berkasbuktipembayaran[0].path}`}
                        />
                      </VStack>
                    </SimpleGrid>
                  </Box>
                ) : (
                  <Text opacity={0.5}>
                    Saat ini belum memiliki Bukti Pembayaran
                  </Text>
                )}
              </Stack>
            </ModalBody>
          ) : (
            <ComponentSpinner h={"525px"} />
          )}

          <ModalFooter>
            <Button
              variant={"outline"}
              colorScheme="ap"
              onClick={handleUnduhSemuaBerkas}
              className="lg-clicky"
            >
              Unduh Semua Berkas
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
