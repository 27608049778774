import { HStack, Image, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function LandingHeader() {
  return (
    <HStack as={Link} to={"/"}>
      <Image
        src={useColorModeValue(
          "/images/logoLight.png",
          "/images/logoDark.png"
        )}
        h={"48px"}
      />
    </HStack>
  );
}
