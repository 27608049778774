import {
  Avatar,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AdminNav from "./AdminNav";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import useScreenWidth from "../lib/useGetScreenWidth";
import { ArrowClockwise, CaretDown, SignOut } from "@phosphor-icons/react";
import { iconSize } from "../const/sizes";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useNavigate } from "react-router-dom";
import SedangKeluar from "./SedangKeluar";

type Props = {
  children: JSX.Element | JSX.Element[];
  active: string[];
};

export default function AdminContainer({ children, active }: Props) {
  const contentBg = useColorModeValue("var(--divider)", "#111827");
  const sw = useScreenWidth();
  const navigate = useNavigate();
  const toast = useToast();

  const buttonBg = useColorModeValue("red.500", "red.200");
  const buttonBgHover = useColorModeValue("red.600", "red.300");
  const buttonBgActive = useColorModeValue("red.700", "red.400");
  const buttonColor = useColorModeValue("white", "black");

  const [loadingKeluar, setLoadingKeluar] = useState<boolean>(false);

  const handleSignout = () => {
    setLoadingKeluar(true);
    const url = `api/logout`;
    clientRequest.then(() => {
      reqWithHeader
        .post(url)
        .then((response) => {
          if (response.data.status === 200) {
            navigate("/");
            toast({
              title: "Berhasil Keluar",
              status: "success",
              duration: 10000,
              isClosable: true,
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {loadingKeluar && <SedangKeluar />}

      <HStack gap={0} minH={"100vh"} align={"stretch"} bg={contentBg}>
        <AdminNav active={active} />

        <Box
          flex={1}
          pl={sw < 770 ? "" : "80px"}
          pb={sw < 770 ? "60px" : ""}
          w={"100%"}
          // maxW={sw < 770 ? "100vw" : "calc(100vw - 95px)"}
        >
          <VStack px={[4, null, 6]} w={"100%"} gap={0} align={"stretch"}>
            <HStack py={4} justify={"space-between"}>
              <Text fontSize={[20, null, 24]} fontWeight={600} noOfLines={1}>
                {active[active.length - 1]}
              </Text>

              <HStack>
                <IconButton
                  aria-label="refreshButton"
                  icon={<Icon as={ArrowClockwise} fontSize={iconSize} />}
                  className="btn-solid"
                  onClick={() => {
                    window.location.reload();
                  }}
                />

                <ColorModeSwitcher fontSize={20} className="btn-solid" ml={0} />

                <Menu>
                  <MenuButton as={Button} px={0} className="btn-solid">
                    <HStack borderRadius={8} p={2} cursor={"pointer"}>
                      <Icon as={CaretDown} fontSize={iconSize} />

                      <Text>Admin</Text>

                      <Avatar name="Jolitos Kurniawan" size={"xs"} />
                    </HStack>
                  </MenuButton>

                  <MenuList minW={"126px"} border={"none"}>
                    <MenuItem
                      bg={buttonBg}
                      color={buttonColor}
                      _hover={{ bg: buttonBgHover }}
                      _active={{ bg: buttonBgActive }}
                      onClick={handleSignout}
                    >
                      <HStack>
                        <Icon as={SignOut} fontSize={iconSize} />
                        <Text>Keluar</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>

            {children}
          </VStack>
        </Box>
      </HStack>
    </>
  );
}
