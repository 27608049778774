import { Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function FullPageLogo() {
  return (
    <VStack id="fullPageLogo" minH={"100vh"} justify={"center"}>
      <Image src="/logo192.png" mb={4} />
      <Text fontSize={[16, null, 18]} fontWeight={500}>
        Mohon tunggu...
      </Text>
    </VStack>
  );
}
