import { Box, HStack, Text, Tooltip } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { userData } from "../const/types";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import ComponentSpinner from "./ComponentSpinner";

export default function InformasiAkun() {
  const [data, setData] = useState<userData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const url = "api/user/logindata";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  if (!loading && data) {
    return (
      <Box>
        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Nama
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data?.name}</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Kategori
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data?.jenis}</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Telepon
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data?.hp}</Text>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Email
          </Text>

          <Text mx={1}>:</Text>

          <Tooltip label={data?.email}>
            <Text noOfLines={1}>{data?.email}</Text>
          </Tooltip>
        </HStack>

        <HStack mb={2} align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Instansi
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data?.institusi}</Text>
        </HStack>

        <HStack align={"flex-start"}>
          <Text w={"130px"} flexShrink={0}>
            Alamat Instansi
          </Text>

          <Text mx={1}>:</Text>

          <Text>{data?.alamat_institusi}</Text>
        </HStack>
      </Box>
    );
  } else {
    return <ComponentSpinner />;
  }
}
