import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import SortTransaksi from "../../components/SortTransaksi";
import TabelTransaksi from "../../components/TabelTransaksi";
import SearchTransaksi from "../../components/SearchTransaksi";

export default function AdminTransaksi() {
  // useScrollToTop();
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Transaksi"]}>
      <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
        <HStack mb={4}>
          <SearchTransaksi />

          <SortTransaksi />
        </HStack>

        <TabelTransaksi />
      </Box>
    </AdminContainer>
  );
}
