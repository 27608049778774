import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "../middleware/RequireAuth";
import MissingPage from "../pages/MissingPage";
import AdminDashboards from "../pages/admin/AdminDashboards";
import AdminKelolaProduk from "../pages/admin/AdminKelolaProduk";
import AdminTransaksi from "../pages/admin/AdminTransaksi";
import AdminKategoriProduk from "../pages/admin/AdminKategoriProduk";
import AdminCustomer from "../pages/admin/AdminCustomer";
import AdminPenguji from "../pages/admin/AdminPenguji";
import AdminFileTemplate from "../pages/admin/AdminFileTemplate";
import AdminBiayaTambahan from "../pages/admin/AdminBiayaTambahan";
import AdminKuisioner from "../pages/admin/AdminKuisioner";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth role={["2"]}>
            <AdminDashboards />
          </RequireAuth>
        }
      />

      <Route
        path="/transaksi"
        element={
          <RequireAuth role={["2"]}>
            <AdminTransaksi />
          </RequireAuth>
        }
      />

      <Route
        path="/kategori-produk"
        element={
          <RequireAuth role={["2"]}>
            <AdminKategoriProduk />
          </RequireAuth>
        }
      />

      <Route
        path="/kelola-produk"
        element={
          <RequireAuth role={["2"]}>
            <AdminKelolaProduk />
          </RequireAuth>
        }
      />

      <Route
        path="/pengguna-customer"
        element={
          <RequireAuth role={["2"]}>
            <AdminCustomer />
          </RequireAuth>
        }
      />

      <Route
        path="/pengguna-penguji"
        element={
          <RequireAuth role={["2"]}>
            <AdminPenguji />
          </RequireAuth>
        }
      />

      <Route
        path="/file-template"
        element={
          <RequireAuth role={["2"]}>
            <AdminFileTemplate />
          </RequireAuth>
        }
      />

      <Route
        path="/biaya-tambahan"
        element={
          <RequireAuth role={["2"]}>
            <AdminBiayaTambahan />
          </RequireAuth>
        }
      />

      <Route
        path="/kuisioner"
        element={
          <RequireAuth role={["2"]}>
            <AdminKuisioner />
          </RequireAuth>
        }
      />

      <Route path="*" element={<MissingPage />} />
    </Routes>
  );
}
