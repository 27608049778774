import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { Box, useColorModeValue } from "@chakra-ui/react";
import TabelFileTemplate from "../../components/TabelFileTemplate";

export default function AdminFileTemplate() {
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Produk", "File Template"]}>
      <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
        <TabelFileTemplate />
      </Box>
    </AdminContainer>
  );
}
