import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import TambahKategoriModal from "../../components/TambahKategoriModal";
import TabelKategoriProduk from "../../components/TabelKategoriProduk";
import SortKategoriProduk from "../../components/SortKategoriProduk";
import SearchKategoriProduct from "../../components/SearchKategoriProduct";

export default function AdminKategoriProduk() {
  // useScrollToTop();
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Produk", "Kategori Produk"]}>
      <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
        <HStack mb={4}>
          <SearchKategoriProduct />

          <SortKategoriProduk />

          <TambahKategoriModal />
        </HStack>

        <TabelKategoriProduk />
      </Box>
    </AdminContainer>
  );
}
