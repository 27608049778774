import React, { useState, useEffect } from "react";
import FullPageLogo from "../components/FullPageLogo";
import { useNavigate } from "react-router-dom";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import { useToast } from "@chakra-ui/react";

type Props = { children: JSX.Element; role?: string[] };

export default function RequireAuth({ children, role }: Props) {
  const [page, setPage] = useState(<FullPageLogo />);

  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    setPage(<FullPageLogo />);

    const url = "api/user/logindata";
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            if (role?.includes(response.data.data.role) || role?.length === 0) {
              setPage(children);
            } else {
              toast({
                title: "Tidak Ada Akses",
                description: "Anda tidak terdaftar untuk autentikasi ini",
                status: "error",
                duration: 10000,
                isClosable: true,
              });
              navigate("/");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Tidak Ada Akses",
            description: "Silahkan masuk terlebih dahulu",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
          navigate("/");
        });
    });
  }, [children, navigate, toast, role]);

  return page;
}
