import React from "react";
import CustomerContainer from "../../components/CustomerContainer";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  HStack,
  Icon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Container from "../../components/Container";
import { CaretRight, Files, Images } from "@phosphor-icons/react";
import { iconSize } from "../../const/sizes";
import useScreenWidth from "../../lib/useGetScreenWidth";
import { Link, useNavigate, useParams } from "react-router-dom";
import TambahFotoSampelModal from "../../components/TambahFotoSampelModal";
import TambahFilePendukungModal from "../../components/TambahFilePendukungModal";
import InformasiAkun from "../../components/InformasiPelanggan";
import StepperPengujian from "../../components/StepperPengujian";
import DividerFullHorizontal from "../../components/DividerFullHorizontal";
import InformasiTransaksi from "../../components/InformasiTransaksi";
import FotoSampelList from "../../components/FotoSampelList";
import DeleteFotoSampelModal from "../../components/DeleteFotoSampelModal";
import useValidateBerkas from "../../globalState/useValidateBerkas";
import FilePendukungList from "../../components/FilePendukungList";
import DeleteFilePendukung from "../../components/DeleteFilePendukung";

export default function PendaftaranPengujian() {
  const sw = useScreenWidth();
  const { fotoSampel, filePendukung } = useValidateBerkas();
  const navigate = useNavigate();
  const { noreg } = useParams();

  return (
    <CustomerContainer active={"Pengujian"}>
      <Container>
        <Box>
          {/* Bread Crumbs */}
          <HStack className="breadcrumb" mt={6}>
            <Link to="/customer" className="breadcrumbItem">
              Beranda
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            <Link to="/customer/pengujian" className="breadcrumbItem">
              Pengujian
            </Link>
            <Icon
              as={CaretRight}
              opacity={0.3}
              className="breadcrumbSeparator"
            />
            s
            <Text className="breadcrumbCurrent breadcrumbItem">
              Unggah Bekas
            </Text>
          </HStack>

          <Text fontSize={[22, null, 24]} fontWeight={600} mt={6} mb={6}>
            Pendaftaran Pengujian Online
          </Text>

          <SimpleGrid
            columns={sw < 900 ? 1 : 2}
            gap={6}
            px={[4, 5, 6]}
            pt={[5]}
            pb={[6]}
            bg={"var(--p500a)"}
            borderRadius={16}
          >
            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Data Pelanggan
              </Text>

              <InformasiAkun />
            </Box>

            <Box>
              <Text fontSize={[18, null, 20]} fontWeight={600} mb={4}>
                Informasi Transaksi
              </Text>

              <InformasiTransaksi />
            </Box>
          </SimpleGrid>
        </Box>

        <Box my={8}>
          <StepperPengujian index={2} />
        </Box>

        <Box
          px={[4, 5, 6]}
          py={[3, 4, 4]}
          borderRadius={16}
          border={"1px solid var(--divider3)"}
          mb={8}
        >
          <Text fontSize={[22, null, 24]} fontWeight={600} mb={[4, 5, 6]}>
            Unggah Berkas
          </Text>

          <Tabs isFitted variant="unstyled">
            <TabList borderRadius={8} bg={"var(--divider)"} mb={4}>
              <Tab
                borderRadius={8}
                _selected={{
                  bg: "p.500",
                  color: useColorModeValue("white", "black"),
                }}
              >
                <Icon as={Images} fontSize={iconSize} mr={[null, null, 2]} />
                {sw >= 768 && (
                  <Text fontWeight={500} fontSize={[12, null, 14]}>
                    Foto Sampel
                  </Text>
                )}
              </Tab>

              <Tab
                borderRadius={8}
                _selected={{
                  bg: "p.500",
                  color: useColorModeValue("white", "black"),
                }}
              >
                <Icon as={Files} fontSize={iconSize} mr={[null, null, 2]} />
                {sw > 768 && (
                  <Text fontWeight={500} fontSize={[12, null, 14]}>
                    File Pendukung
                  </Text>
                )}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={0} pb={2}>
                {sw < 768 && (
                  <Text fontSize={[16, null, 18]} fontWeight={600} mb={2}>
                    Foto Sampel
                  </Text>
                )}
                <Text mb={2}>Silakan Tambahkan Foto Sampel Uji Anda!!</Text>
                <HStack mb={4} justify={"space-between"}>
                  <TambahFotoSampelModal />

                  <DeleteFotoSampelModal />
                </HStack>
                <Text
                  fontSize={[12, null, 14]}
                  color={useColorModeValue("red.500", "red.300")}
                >
                  *Minimal 1 foto sampel
                </Text>
                <Text
                  fontSize={[12, null, 14]}
                  color={useColorModeValue("red.500", "red.300")}
                  mb={4}
                >
                  *Pilih dahulu Foto Sampel untuk menghapusnya
                </Text>
                <DividerFullHorizontal mb={5} />
                <FotoSampelList />
              </TabPanel>

              <TabPanel p={0} pb={2}>
                {sw < 768 && (
                  <Text fontSize={[16, null, 18]} fontWeight={600} mb={2}>
                    File Pendukung
                  </Text>
                )}

                <Alert
                  flexDir={"column"}
                  alignItems={"flex-start"}
                  variant={"left-accent"}
                  borderRadius={8}
                  mb={4}
                  minW={"100%"}
                >
                  <AlertTitle
                    color={useColorModeValue("blue.500", "blue.200")}
                    fontSize={[18, null, 20]}
                    mb={1}
                  >
                    Unduh Template Excel File Pendukung Anda Disini!!
                  </AlertTitle>
                  <AlertDescription mb={3}>
                    Silahkan klik tombol dibawah ini untuk mengunduh Excel untuk
                    File Pendukung anda.
                  </AlertDescription>
                  <Button colorScheme="blue" className="lg-clicky">
                    Unduh Berkas
                  </Button>
                </Alert>

                <Text
                  fontSize={[12, null, 14]}
                  color={useColorModeValue("red.500", "red.300")}
                  mb={4}
                >
                  *Pilih dahulu File Pendukung untuk menghapusnya
                </Text>

                <HStack mb={4} justify={"space-between"}>
                  <TambahFilePendukungModal />

                  <DeleteFilePendukung />
                </HStack>

                <DividerFullHorizontal mb={4} />

                <FilePendukungList />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <HStack justify={"flex-end"} gap={2} mb={8}>
          <Button
            variant={"ghost"}
            colorScheme="ap"
            className="lg-clicky "
            as={Link}
            to={`/customer/pengujian/pendaftaran-pengujian/${noreg}/pilih-metode-uji`}
          >
            Kembali
          </Button>

          <Button
            colorScheme="ap"
            className="lg-clicky btn-ap"
            onClick={() => {
              navigate(
                `/customer/pengujian/pendaftaran-pengujian/${noreg}/detail-pengujian`
              );
            }}
            isDisabled={fotoSampel && filePendukung ? false : true}
          >
            Lanjutkan
          </Button>
        </HStack>
      </Container>
    </CustomerContainer>
  );
}
