import axios from "axios";

const reqWithHeader = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  headers: {
    Accept: "application/json",
  },
});

// reqWithHeader.interceptors.response.use(null, (err) => {
//   const error = {
//     status: err.response?.status,
//     original: err,
//     validation: {},
//     message: null,
//   };

//   if(err.response?.status === 422){

//   }
// });

export default reqWithHeader;
