import React from "react";
import AdminContainer from "../../components/AdminContainer";
import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import SearchKuisioner from "../../components/SearchKuisioner";
import SortKuisioner from "../../components/SortKuisioner";
import TambahKuisionerModal from "../../components/TambahKuisionerModal";
import TabelKuisioner from "../../components/TabelKuisioner";

export default function AdminKuisioner() {
  const bg = useColorModeValue("white", "dark");

  return (
    <AdminContainer active={["Produk", "Kuisioner"]}>
      <Box p={[4, null, 6]} bg={bg} borderRadius={16}>
        <HStack mb={4}>
          <SearchKuisioner />

          <SortKuisioner />

          <TambahKuisionerModal />
        </HStack>

        <TabelKuisioner />
      </Box>
    </AdminContainer>
  );
}
