import { Button, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import clientRequest from "../const/clientRequest";
import reqWithHeader from "../const/reqWithHeader";
import useTrigger from "../globalState/useTrigger";

type Props = {
  noreg: number;
};

export default function ChangeStatusDalamPengujian({ noreg }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { trigger, setTrigger } = useTrigger();

  const onDalamPengujian = () => {
    setLoading(true);
    // TODO integrate api change status to Dalam Pengujian
    const url = `api/transaction/${noreg}/confirmpengujian`;
    clientRequest.then(() => {
      reqWithHeader
        .get(url)
        .then((r) => {
          if (r.status === 200) {
            toast({
              title: r.data.message,
              status: "success",
              duration: 10000,
              isClosable: true,
            });
            setTrigger(!trigger);
          }
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: "Konfirmasi Dalam Pengujian Gagal",
            description: "Maaf terjadi kesalahan",
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Button colorScheme="ap" onClick={onDalamPengujian} isLoading={loading}>
      Dalam Pengujian
    </Button>
  );
}
