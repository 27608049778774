import { Button, useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Transaksi } from "../const/types";
import clientRequest from "../const/clientRequest";
import axios from "axios";

type Props = {
  noreg: number;
  data: Transaksi;
};

export default function UnduhHasilPengujian({ noreg, data }: Props) {
  const navigate = useNavigate();
  const toast = useToast();

  const handleUnduh = () => {
    if (!data?.is_quesioner) {
      navigate(`/customer/kuisioner/${noreg}`);
    } else {
      const url = `${process.env.REACT_APP_BASE_URL}api/berkas/download/${noreg}/hasil`;
      clientRequest.then(() => {
        axios
          .get(url, {
            responseType: "blob",
            withCredentials: true,
            xsrfCookieName: "XSRF-TOKEN",
            xsrfHeaderName: "X-XSRF-TOKEN",
          })
          .then((r) => {
            if (r.status === 200) {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([r.data])
              );
              const link = document.createElement("a");
              link.href = downloadUrl;
              link.setAttribute(
                "download",
                `Hasil Pengujian (${data?.nama_sertifikat}).${r.data.type
                  .split("/")
                  .pop()}`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(downloadUrl);
            }
          })
          .catch((e) => {
            console.log(e);
            toast({
              title: "Gagal mengunduh",
              description: e?.response?.data?.message,
              status: "error",
              duration: 10000,
              isClosable: true,
            });
          });
      });
    }
  };

  return (
    <Button
      w={"100%"}
      colorScheme="ap"
      className="lg-clicky btn-ap"
      onClick={handleUnduh}
    >
      Unduh Hasil Pengujian
    </Button>
  );
}
